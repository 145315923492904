import React, { Component } from 'react';
import ReactTwitchEmbedVideo from 'react-twitch-embed-video';

class TwitchStream extends Component {
  render() {
    return (
      <section id="twitch">
        <div className="row">
          <div className="twelve columns collapsed">
            <ReactTwitchEmbedVideo channel="2sBs" muted width="100%" height="575px" />
          </div>
        </div>
      </section>
    );
  }
}

export default TwitchStream;

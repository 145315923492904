import React, { Component } from "react";
import { Timeline } from "react-twitter-widgets";

class About extends Component {
  render() {
    return (
      <section id="about">
        <div className="row">
          <div className="three columns">
            <img
              className="profile-pic"
              src="images/profilepic.jpg"
              alt="Luther Minshull Profile Pic"
            />
          </div>
          <div className="nine columns main-col">
            <h2>About Me</h2>
            <p>
              After following the CSGO scene as a fan for a number of years
              locally, and playing in some amateur-level teams, I started
              casting a few matches for fun. Since then, I've casted events run
              by a number of local and international organisations, including{" "}
              <a href="https://teamliquid.com">Team Liquid</a>
              {", "}
              <a href="https://www.eslgaming.com/">ESL</a>
              {", "}
              <a href="https://ordergaming.gg/">ORDER</a>
              {" and "}
              <a href="https://letsplay.live">LetsPlayLive</a>. I love casting
              and love the esports community, and am always looking for more
              broadcasting opportunities!
            </p>
            <p>
              Outside of esports, I'm studying a Bachelor of IT (Computer
              Science major) at QUT. For non-esports related enquiries, check
              out my <a href="https://lutherminshull.com">personal website</a>.
            </p>
            <div className="row">
              <div className="columns contact-details">
                <h2>Contact Details</h2>
                <p className="address">
                  <span>Luther Minshull</span>
                  <br />
                  <span>
                    <a href="mailto://business@its2sBs.com">
                      business@its2sBs.com
                    </a>
                  </span>
                  <br />
                  <span>
                    <a href="https://discord.com/users/70792011375980544/">
                      2sBs#6575
                    </a>
                  </span>
                  <br />
                  <span>
                    You can also reach me on any of the social platforms linked
                    above, or with the form below. I love working with people
                    who are also passionate about esports, so feel free to get
                    in touch :){" "}
                  </span>
                </p>
              </div>
              <div className="columns download">
                <Timeline
                  dataSource={{
                    sourceType: "profile",
                    screenName: "its2sBs",
                  }}
                  options={{
                    theme: "dark",
                    width: "600",
                    height: "300",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;

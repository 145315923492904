import React, { Component } from "react";

class Footer extends Component {
  render() {
    const social = [
      {
        name: "email",
        url: "mailto://business@its2sBs.com",
        className: "far fa-envelope",
      },
      {
        name: "twitter",
        url: "http://twitter.com/its2sBs",
        className: "fa fa-twitter",
      },
      {
        name: "linkedin",
        url: "https://www.linkedin.com/in/luther-minshull-61b6581aa/",
        className: "fa fa-linkedin",
      },
      {
        name: "twitch",
        url: "https://twitch.tv/2sBs",
        className: "fab fa-twitch",
      },
      {
        name: "instagram",
        url: "http://instagram.com/luther.minshull",
        className: "fa fa-instagram",
      },
      {
        name: "github",
        url: "https://github.com/2sBs",
        className: "fa fa-github",
      },
      {
        name: "steam",
        url: "https://steamcommunity.com/id/2sBs",
        className: "fab fa-steam",
      },
      {
        name: "discord",
        url: "https://discord.com/users/70792011375980544/",
        className: "fab fa-discord",
      },
    ];

    var networks = social.map(function (network) {
      return (
        <li key={network.name}>
          <a href={network.url}>
            <i className={network.className}></i>
          </a>
        </li>
      );
    });

    return (
      <footer>
        <div className="row">
          <div className="twelve columns">
            <ul className="social-links">{networks}</ul>

            <ul className="copyright">
              <li>&copy; 2021 Luther Minshull</li>
            </ul>
          </div>
          <div id="go-top">
            <a className="smoothscroll" title="Back to Top" href="#home">
              <i className="icon-up-open"></i>
            </a>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
